// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCdDMoyYrxII2fN6FQFpFXcEeiH2Dsl41c",
    authDomain: "display-client.firebaseapp.com",
    projectId: "display-client",
    storageBucket: "display-client.appspot.com",
    messagingSenderId: "108840938632",
    appId: "1:108840938632:web:2bdf53c0938892e74d033d",
    measurementId: "G-41SYXDZ0PW"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app);
