import "./App.css";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import SelectedSchedule from "./views/SelectedSchedule";
import LandingPage from "./components/LandingPage";
import CollectionSchedule from "./views/CollectionSchedule";
import {useEffect, useState} from "react";
import Login from "./views/auth/Login";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {storage} from "./firebase";
function App() {

    const [user, setUser] = useState(true);

    // useEffect(() =>{
    //     console.log("File Blob Test")
    //     const httpsReference = ref(storage, 'https://firebasestorage.googleapis.com/v0/b/display-client.appspot.com/o/10%20Second%20Video_%20Stuffed%20Grilled%20Sandwiches.mp415bbd581-9f96-4334-a0c2-8f721bf1344e?alt=media&token=4695ee9a-a72e-4c30-b79f-d1d7bb16ecca');
    //
    //     getDownloadURL(httpsReference)
    //         .then((url) => {
    //
    //             console.log("URL : ", url)
    //
    //             // This can be downloaded directly:
    //             const xhr = new XMLHttpRequest();
    //             xhr.responseType = 'blob';
    //             xhr.onload = (event) => {
    //                 const blob = xhr.response;
    //                 console.log("xhr : ", URL.createObjectURL(blob))
    //             };
    //             xhr.open('GET', url);
    //             xhr.send();
    //         })
    //         .catch((error) => {
    //             // Handle any errors
    //         });
    //
    // },[])


    return (
        <Router>
            <Switch>
                <Route exact path="/:companyName/:branchName/:collectionName/:scheduleName">
                    {user ? <SelectedSchedule/> : <Login setUser={setUser}/>}
                </Route>
                <Route exact path="/:companyName/:branchName/:collectionName">
                    {user ? <CollectionSchedule/> : <Login setUser={setUser}/>}
                </Route>
                <Route exact path="/">
                    <LandingPage/>
                </Route>
                <Redirect to="/" />

            </Switch>

        </Router>
    );
}

export default App;
