import React, {useEffect, useState} from 'react';
import {Box, Button, Checkbox, FormControlLabel, Grid, Paper, TextField, Typography} from "@mui/material";
import logo from "../../assets/images/Full Logo.png";
import {makeStyles} from "@mui/styles";
import { useHistory} from "react-router-dom";
import backgroundImage from '../../assets/images/bgv2.jpg';
import {signInWithEmailAndPassword} from "firebase/auth";
import {useSnackbar} from "notistack";
import {collection, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {db} from "../../firebase";

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        height: "100vh",

        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
    },
    test: {
        [theme.breakpoints.only('xl')]: {
            padding: "5px 150px",
            minHeight: "100vh",
        },
        [theme.breakpoints.only('lg')]: {
            padding: "5px 100px",
            minHeight: "100vh",
        },
        [theme.breakpoints.only('md')]: {
            padding: "5px 100px",
            minHeight: "100vh",
        },
        [theme.breakpoints.only('sm')]: {
            padding: "50px 150px",
            minHeight: "50vh",
        },
        [theme.breakpoints.only('xs')]: {
            padding: "20px 50px",
        },
    },
}))
const initialFValues = {
    userName: '',
}

const Login = (props) => {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState();
    const [dataList, setDataList] = useState([]);

    const [values, setValues] = useState(initialFValues);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles()
    const history = useHistory();

    useEffect(() =>{
        const colRef = collection(db, "user");
        const dataQuery = query(colRef,
            where("role", "in", ["Admin", "Manager"])
        );

        onSnapshot(
            dataQuery,
            async (snapShot) => {
                let list = [];
                console.log("1 snap : ");
                let count = true;
                snapShot.docs.forEach((doc, index) => {
                    // console.log("2snap : ", doc.data());
                    if (count) {

                        list.push({id: doc.id, ...doc.data()});
                    }
                });
                setDataList(list);
                console.log("setDataList list : ", list)

            },
            (error) => {
                console.log(error);
            }
        );



            },[])

    const onSubmit = (e) => {
        // console.log("button submit : ", values.email)
        e.preventDefault();
        console.log("button submit : ", userName)
        for (let i=0; i < dataList.length; i++){
            if (userName === dataList[i].username){
                enqueueSnackbar("Authentication Successfully!", {variant:"success"})
                props.setUser(true)
                break;
            } else if (i === dataList.length - 1){
                enqueueSnackbar("Authentication Failed!", {variant:"error"})
            }
        }
        // const email = emailRef.current.value;
        // const password = psdRef.current.value;
        // props.setUser(true);
    };

    return (
        <Box className={classes.root}>
            <Grid container direction={"row"} justifyContent="center">
                <Grid container justifyContent="center"
                      item lg={5.9} md={6} sm={12} xs={12} direction={"column"}

                      sx={{
                          height: "90vh",
                          // background: "#FBFBFB",
                          borderRadius: "10px",
                          padding: "5px 50px"
                      }}>
                    <Grid item align={"center"} sx={{}}>
                        <img src={logo} style={{width:"300px"}}/>
                    </Grid>

                    <form onSubmit={onSubmit} >

                        {/* ------------------username--------------------- */}
                        <Grid container direction={"column"}>
                            <Grid item sx={{paddingTop: "75px"}}>
                                {/*<Typography sx={{color: "#7b92ec",}}>Email</Typography>*/}
                                <Grid item sx={{}}>
                                    <TextField margin="dense" id="outlined-basic"
                                               sx={{width: "100%", minWidth: "150px"}}
                                               variant="outlined"
                                               label={"Username"}
                                               value={userName}
                                               type={"password"}
                                               onChange={(e) => setUserName(e.target.value)}
                                    />
                                    {/*<CustomTextField/>*/}
                                </Grid>
                            </Grid>

                            {/* -----------------------passowrd---------------------------- */}

                            {/*<Grid container item direction={"column"}>*/}
                            {/*    <Grid item sx={{paddingTop: "20px"}}>*/}
                            {/*        /!*<Typography sx={{color: "#7b92ec",}}>Password</Typography>*!/*/}
                            {/*        <Grid item sx={{}}>*/}
                            {/*            <TextField margin="dense" id="outlined-basic"*/}
                            {/*                       sx={{width: "100%", minWidth: "150px"}}*/}
                            {/*                       variant="outlined" label={"Password"}*/}
                            {/*                       value={password}*/}
                            {/*                       type={"password"}*/}
                            {/*                       onInput={(e) => setPassword(e.target.value)}/>*/}
                            {/*            /!*<CustomTextField/>*!/*/}
                            {/*        </Grid>*/}
                            {/*    </Grid>*/}
                            {/*</Grid>*/}

                            <Grid sx={{paddingTop: "30px"}}>
                                {/*<Button variant={"contained"} sx={{width: "100%"}}>Login</Button>*/}
                                {/*<Link to="/dashboard">*/}
                                <Button type={"submit"} variant={"contained"} color={"primary"}
                                        sx={{width: "100%", height: "50px", borderRadius: "10px"}}>Verify</Button>
                                {/*</Link>*/}
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>

        </Box>
    );
};

export default Login;
