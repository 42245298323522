import React, {useEffect, useState} from 'react';
import {
    collection,
    query,
    where,
    onSnapshot, orderBy,
} from "firebase/firestore";
import {db} from "../firebase";
import {useParams} from "react-router-dom";
import Display from "../components/Display";
import LandingPage from "../components/LandingPage";

const SelectedSchedule = () => {
    const [data, setData] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [currentId, setCurrentId] = useState(0);
    let { companyName, branchName, collectionName, scheduleName } = useParams();
    useEffect(() => {

        const colRef = collection(db, "schedule");
        const dataQuery = query(colRef, where("companyName", "==", companyName),
            where("branchName", "==", branchName),
            where("collectionName", "==", collectionName),
            where("scheduleName", "==", scheduleName),
            orderBy("scheduleDateTime", "desc")
        );

        onSnapshot(
            dataQuery,
            (snapShot) => {
                let list = [];
                snapShot.docs.forEach((doc) => {
                    list.push({id: doc.id, ...doc.data()});
                });
                console.log("LIST : ", list)
                console.log("LIST data : ", list[0].selectAds)
                setData(list[0].selectAds);
            },
            (error) => {
                console.log(error);
            }
        );

    }, []);

    useEffect(() => {
        if (data.length !== 0){
            setCurrentId(data[0].id)
        }
    }, [data]);

    const onChange = (slideIndex) => {
        setCurrentId(data[slideIndex].id)
        setCurrentSlide(slideIndex);
    }

    return (
        data.length !== 0 ?
            <Display data={data} currentSlide={currentSlide} currentId={currentId} onChange={onChange}/>
            :
            <LandingPage/>
    )
}

export default SelectedSchedule;
