import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {collection, onSnapshot, query, where, orderBy} from "firebase/firestore";
import {getStorage, ref, getDownloadURL} from "firebase/storage";
import {db, storage} from "../firebase";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import Display from "../components/Display";
import LandingPage from "../components/LandingPage";


const CollectionSchedule = () => {

    let {companyName, branchName, collectionName} = useParams();
    const [data, setData] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [sessionList, setSessionList] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [currentId, setCurrentId] = useState(0);

    function compare(a, b) {
        const compareA = a.scheduleDateTime.seconds;
        const compareB = b.scheduleDateTime.seconds;

        let comparison = 0;
        if (compareA > compareB) {
            comparison = 1;
        } else if (compareA < compareB) {
            comparison = -1;
        }
        return comparison;
    }

    useEffect(() => {
        const timestamp = firebase.firestore.Timestamp.fromDate(new Date());

        const colRef = collection(db, "schedule");
        const dataQuery = query(colRef,
            where("companyName", "==", companyName),
            where("branchName", "==", branchName),
            where("collectionName", "==", collectionName),
            orderBy("scheduleDateTime", "desc")
        );

        onSnapshot(
            dataQuery,
            async (snapShot) => {
                let list = [];
                console.log("1 snap : ");
                let count = true;
                snapShot.docs.forEach((doc, index) => {
                    // console.log("2snap : ", doc.data());
                    if (count) {
                        // console.log("3 snap : ", index);
                        list.push({id: doc.id, ...doc.data()});
                        // console.log("DataList : ", {id: doc.id, ...doc.data()});
                        // console.log("Data : ", doc.data().selectAds);
                        count = doc.data().scheduleDateTime.seconds > timestamp.seconds;
                    }
                });
                setDataList(list.sort(compare));
                console.log("setDataList list : ", list)

            },
            (error) => {
                console.log(error);
            }
        );


    }, []);


    useEffect(async () => {
        console.log("------------------- blob create --------------------")
        if (dataList.length) {
            console.log("Main dataList : ", dataList)

                let value = dataList[0];
                console.log("for value : ", value)
                let list = [];
                for (let j = 0; j < value.selectAds.length; j++) {
                    let data = value.selectAds[j]
                    console.log("for data : ", data)
                    let url = await getDownloadURL(ref(storage, data.file));

                    console.log("URL : ", url)
                    // This can be downloaded directly:
                    const xhr = new XMLHttpRequest();
                    xhr.responseType = 'blob';
                    xhr.onload = async (event) => {
                        const blob = await xhr.response;
                        console.log("xhr : ", URL.createObjectURL(blob))
                        console.log("xhr j : ", j)
                        list.push({blob: URL.createObjectURL(blob), ...data});
                        if (j === value.selectAds.length - 1) {
                            setSessionList([{...value, selectAds: list}])
                        }
                    };
                    xhr.open('GET', url);
                    xhr.send();
                }
        }

    }, [dataList]);


    useEffect(() => {
        if (sessionList.length) {
            console.log("sessionList : ", sessionList[0])
            console.log("sessionList-selectAds: ", sessionList[0].selectAds)
            console.log("sessionList-selectAds[0] : ", sessionList[0].selectAds[0])
            // console.log("dataList Updated & not empty")
        }
    }, [sessionList]);


    useEffect(() =>{
        setData([]);
        if (sessionList.length) {
            setTimeout(
                () => {
                    setData(sessionList[0].selectAds);
                    setCurrentId(sessionList[0].selectAds[0].id)
                },
                1000
            );
        }
    },[sessionList])

    useEffect(() => {
        const timestamp = firebase.firestore.Timestamp.fromDate(new Date());
        if (dataList.length && dataList.length >= 2) {
            if (dataList[1].scheduleDateTime.seconds < timestamp.seconds) {
                // setSessionList([]);
                setDataList((item) => item.filter((_, index) => index !== 0));
                console.log("Update List : ", dataList)
            }else {
                console.log("Now Set start : ", dataList[0].scheduleDateTime.seconds)
                console.log("ID : ", dataList[0].id)
                console.log("Now timestamp : ", timestamp.seconds)
                console.log("Next Set start: ", dataList[1].scheduleDateTime.seconds)
                console.log("ID: ", dataList[1].id)
            }
        }
        console.log("setData : ", data)

    }, [currentId, data]);

    const onChange = (slideIndex) => {
        setCurrentId(data[slideIndex].id)
        setCurrentSlide(slideIndex);
    }

    return (
        data.length !== 0 ?
            <Display data={data} currentSlide={currentSlide} currentId={currentId} onChange={onChange}/>
            :
            <LandingPage/>
    );
};

export default CollectionSchedule;
