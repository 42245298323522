import React, {useEffect, useState} from 'react';
import {Carousel} from 'react-bootstrap';
import styled from "styled-components";
import ReactPlayer from "react-player";


const Slide = styled.div`
  height: 100vh;
  width: 100vw;
  flex-shrink: 0;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: 750ms all ease-in-out;
`;

const VideoPlay = styled.div`
  height: 100vh;
  width: 100vw;
  flex-shrink: 0;
  background-position: center;
  background-size: cover;
  transition: 750ms all ease-in-out;
  overflow: hidden;
`;

const Display = (props) => {

    const {data, currentSlide, currentId, onChange} = props;

    function orderByOrderValue(a, b) {
        if (a.order < b.order) {
            return -1;
        }
        if (a.order > b.order) {
            return 1;
        }
        return 0;
    }

    // useEffect(() =>{
    //     console.log("display data : ", data)
    // })
    return (
        <>
            <audio>
                <source/>
            </audio>
            {data.length !== 0 &&
                <Carousel pause={false} controls={false} onSelect={onChange}>
                    {
                        data.sort(orderByOrderValue).map((fileUrl) => (

                            <Carousel.Item key={fileUrl.id} interval={fileUrl.duration * 1000} className={"bg-dark"}>
                                {
                                    fileUrl.fileType === "image" &&
                                    <Slide
                                        key={fileUrl.scheduleItemId}
                                        style={{
                                            backgroundImage: `url(${fileUrl.blob})`,
                                        }}
                                    />
                                }
                                {
                                    data.length === 1 ?
                                        (fileUrl.fileType === "video" && <VideoPlay
                                            key={fileUrl.scheduleItemId}
                                        >
                                            <ReactPlayer loop={true} key={`${currentSlide}`} width="100%"
                                                         height={"100%"}
                                                         url={fileUrl.blob} playing={fileUrl.id === currentId} muted
                                            />
                                        </VideoPlay>)
                                        :
                                        (fileUrl.fileType === "video" && <VideoPlay
                                            key={fileUrl.scheduleItemId}
                                        >
                                            <ReactPlayer key={`${currentSlide}`} width="100%" height={"100%"}
                                                         url={fileUrl.blob} playing={fileUrl.id === currentId} muted
                                            />
                                        </VideoPlay>)
                                }
                            </Carousel.Item>

                        ))
                    }
                </Carousel>
            }
        </>
    )
}

export default Display;
